<!-- https://mdbootstrap.com/docs/angular/forms/contact/-->
<div class="container">
  <!-- Default form contact -->
  <form class="text-center border border-light p-5">

    <p class="h4 mb-4">Kontaktieren sie uns</p>

    <!-- Name -->
    <input type="text" formControlName="contactFormName" id="defaultContactFormName" mdbInput class="form-control mb-4"
      placeholder="Name">

    <!-- Email -->
    <input type="email" formControlName="contactFormEmail" id="defaultContactFormEmail" mdbInput
      class="form-control mb-4" placeholder="E-Mail">

    <!-- Subject -->
    <label>Betreff</label>
    <select formControlName="contactFormSubjects" class="browser-default custom-select mb-4">
      <option value="" disabled>Bitte auswählen</option>
      <option value="1" selected>Rückmeldung</option>
      <option value="2">Anfrage stellen</option>
      <option value="3">Termin für Verkaufsgespräch</option>
    </select>

    <!-- Message -->
    <div class="form-group">
      <textarea formControlName="contactFormMessage" class="form-control rounded-0" mdbInput
        id="exampleFormControlTextarea2" rows="3" placeholder="Nachricht"></textarea>
    </div>



    <!-- Send button -->
    <button mat-raised-button color="primary" outline="true" block="true" class="z-depth-0 my-4 waves-effect"
      mdbWavesEffect type="submit">Senden</button>

  </form>
  <!-- Default form contact -->
</div>
