import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/kfz-mueller/contact/contact.component';
import { MapComponent } from './components/kfz-mueller/map/map.component';
import { NewModelsComponent } from './components/kfz-mueller/new-models/new-models.component';
import { OurServicesComponent } from './components/kfz-mueller/our-services/our-services.component';
import { TeamComponent } from './components/kfz-mueller/team/team.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'services', component: OurServicesComponent, pathMatch: 'full' },
  { path: 'new-models', component: NewModelsComponent, pathMatch: 'full' },
  { path: 'team', component: TeamComponent, pathMatch: 'full' },
  { path: 'map', component: MapComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactComponent, pathMatch: 'full' },

  { path: '**', component: HomeComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
