<div style=" overflow-y: hidden;">

  <div style="padding: 2px; margin-top: 8px; overflow-y: scroll;" class="container">

    <h1 style="text-align: center;">Neue Modelle</h1>

    <mat-card class="example-card" appMaterialElevation [defaultElevation]="0" [raisedElevation]="24"
      style="margin: 4px;">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Jaguar I-Pace</mat-card-title>
        <mat-card-subtitle>Elektrisch</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="https://cdn.motor1.com/images/mgl/3o0Xx/s3/2021-jaguar-i-pace.jpg" alt="Jaguar I-Pace">
      <mat-card-content>
        <p>
          Der Jaguar I-PACE gewinnt bei den begehrten Marcus Awards des ÖAMTC.
          Gleich in zwei Kategorien wurde der erste vollelektrische Jaguar von der Jury zum Sieger gekürt.
        </p>
      </mat-card-content>
      <!-- <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions> -->
    </mat-card>

    <mat-card class="example-card" appMaterialElevation [defaultElevation]="0" [raisedElevation]="24"
      style="margin: 4px;">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Jaguar F-Type</mat-card-title>
        <mat-card-subtitle>Verbrenner</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="https://cdn.motor1.com/images/mgl/mLy8B/s1/2021-jaguar-f-type.jpg" alt="Jaguar I-Pace">
      <mat-card-content>
        <p>
          Der F-TYPE ist ein reinrassiger Jaguar Sportwagen. Er vereint atemberaubende Performance, direktes
          Ansprechverhalten und agiles Handling.
        </p>
      </mat-card-content>
      <!-- <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions> -->
    </mat-card>

  </div>
</div>
