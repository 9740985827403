<div style=" overflow-y: hidden;">
  <div style="padding: 2px; margin-top: 8px; overflow-y: scroll;" class="container">
    <!-- <mat-card class="example-card" appMaterialElevation [defaultElevation]="0" [raisedElevation]="24"
      style="margin: 4px;"> -->
    <mat-card style="margin: 4px;">
      <div class="col">
        <div class="row">
          <mat-tab-group mat-align-tabs="center" style="background-color:white; width: 100%;">
            <mat-tab label="Übersicht">
              <img class="cover" src=" /assets/img/anfahrt.jpg">
            </mat-tab>

            <mat-tab label="Spezialleistungen">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <h3 style="color: #8c143f;">Spezialreparatur</h3>
                      <p style="color: #8c143f;">Wir sind mit unserem Fach- & Spezialwissen für Sie
                        da, wenn bei Ihnen etwas schief geht und Liebgewon-
                        nenes kaputt geht. Bei Schäden jeglicher Art sorgen
                        wir als Spezialtischlerei dafür, dass Ihr Stück wieder
                        funktioniert und makellos ist.</p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;">Spezialsanierung</h3>
                      <p style="color: #8c143f;">Ihr wertgeschätztes Lieblingsobjekt trotz Abnutzung
                        zu behalten lohnt sich! Zusammen mit unseren
                        Partnerbetrieben decken wir ein breites Leistungs-
                        spektrum ab und können aus alt fast wieder neu
                        machen.</p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;">Spezialservice</h3>
                      <p style="color: #8c143f;">Vorsorge ist besser als Nacharbeiten und schützt
                        vor vermeidbaren Schäden. Wir tragen mit unserer
                        langjährigen Erfahrung und Verlässlichkeit dazu bei,
                        dass es erst gar nicht zu Unanehmlichkeiten kommt.</p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;">Spezialfertigung</h3>
                      <p style="color: #8c143f;">Wir unterstützen Sie mit
                        Fachwissen, Genauigkeit und
                        Kompetenz dabei, Ihre Ideen
                        und Pläne zu realisieren.</p>
                    </div>
                  </div>
                  <div class="col" class="center"> <img style="text-align: center;" src="/assets/img/spezial.jpg"></div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Leitbild">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <h3 style="color: #8c143f;">Leitbild</h3>
                      <p style="color: #8c143f;">Wir sehen uns als eine moderne Spezialtischlerei,
                        welche den Herausforderungen unserer Zeit positiv
                        gegenüber steht.
                      </p>
                      <p style="color: #8c143f;">
                        Der Schnelllebigkeit und der Automatisierung be-
                        gegen wir mit Handwerks-Kunst und persönlichem
                        Kundenkontakt. Dazu zählt auch das Verarbeiten
                        von natürlichen Produkten, sowie eine bestmögliche
                        Verwendung der Arbeitsmaterialien.
                      </p>
                      <p style="color: #8c143f;">
                        Es ist uns wichtig, dass SIE durch unsere Arbeit in
                        IHREM Umfeld einen MEHRWERT wiederfinden.
                        „Wenn Schwieriges kein Problem, sondern eine
                        Herausforderung ist.“
                      </p>
                      <p style="color: #8c143f;">
                        <strong>Die Spezialtischlerei</strong>
                      </p>
                    </div>
                    <div class="row" class="center">
                      <img style="text-align: center;" src="/assets/img/spaene_1.jpg">
                    </div>
                  </div>
                  <div class="col" class="center"> <img style="text-align: center;" src="/assets/img/1.jpg"></div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Tradition">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <h3 style="color: #8c143f;">Tradition</h3>
                      <p style="color: #8c143f;">
                        <strong>1969</strong> gegründet von Alois Tscheschner<br>
                        <strong>1990</strong> von Sohn Engelbert Tscheschner<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; übernommen und weitergeführt<br>
                        <br>
                        Im Laufe der Zeit haben wir unseren <strong>Meisterbetrieb
                          am Schloßbergplatz</strong> modernisiert und an heutige
                        Gegebenheiten angepasst und dabei die Tradition
                        bewahrt.
                        <br>
                        <br>
                        Wir haben Spezialgebiete gesucht und
                        entwickelten uns somit von einer reinen Möbeltischlerei mit einem breitgefächerten
                        Leistungsspektrum <strong>zur Spezialtischlerei</strong>
                        mit jahrzehnterlanger Erfahrung
                        <strong>in den von uns angebotenen
                          Spezialleistungen</strong>
                      </p>
                    </div>

                    <div class="row" class="center">
                      <img style="text-align: center;" src="/assets/img/spaene_2.jpg">
                    </div>
                  </div>
                  <div class="col" class="center"> <img style="text-align: center;" src="/assets/img/2.png"></div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Team">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p><br></p>
                    <div class="row" class="center">
                      <img style="text-align: center;" src="/assets/img/thomas_goetz.jpg">
                    </div>

                    <p style="color: #8c143f;" class="center">
                      Thomas Götz
                    </p>
                    <p style="color: #8c143f;" class="center"><strong>Firmeninhaber</strong></p>
                    <div class="row" class="center">
                      <img style="text-align: center;" src="/assets/img/sasha_swatschina.jpg">
                    </div>
                    <p style="color: #8c143f;" class="center">
                      Sasha Swatschina
                    </p>
                    <p style="color: #8c143f;" class="center"><strong>Firmeninhaber</strong></p>
                  </div>
                  <div class="col" class="center"> <img style="text-align: center;" src="/assets/img/3.jpg"></div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Anfahrt">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <h1 style="color: #8c143f;"><strong>Anfahrt</strong></h1>
                      <p style="color: #8c143f;">
                        So finden Sie zu uns ...<br><br>
                        Unser Meisterbetrieb ist direkt am Schloßbergplatz
                        an der ersten Stufe des Stiegenaufganges
                        zum Schloßberg.
                        <br><br>
                      </p>
                      <h3 style="color: #8c143f;"><strong>Öffentliche Verkehrsmittel</strong></h3>
                      <p style="color: #8c143f;">
                        • von Richtung Hauptbahnhof (Straßenbahn):
                        Linie 3 bis Hauptplatz-Congress
                        umsteigen
                        Linie 5 bis Schloßbergplatz/Murinsel
                      <p style="color: #8c143f;">
                        • von Andritz oder Puntigam (Straßenbahn):
                        Linie 5 Station Schloßbergplatz/Murinsel
                      </p>
                      <h3 style="color: #8c143f;"><strong>Mit dem PKW</strong></h3>
                      <p style="color: #8c143f;">
                        • Parkgarage in der Nähe
                        Kastner & Öhler Parkgarage
                        Kaiser-Franz-Josef-Kai 8
                      </p>
                      <p style="color: #8c143f;">
                        • Direkte Zufahrt zum Schloßbergplatz
                        Für Ladetätigkeit ist die direkte Zufahrt
                        zum Schloßbergplatz Werktags bis 10 Uhr
                        gestattet.
                      </p>
                    </div>

                  </div>
                  <div class="col" class="center"> <img style="text-align: center;"
                      src="/assets/img/plan_der_hobel.jpg"></div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Kontakt">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <h1 style="color: #8c143f;"><strong>Kontakt</strong></h1>
                      <p style="color: #8c143f;">
                        Bei Interesse an unseren Leistungen oder sonstigen
                        Fragen stehen wir Ihnen gerne unter folgenden Kontaktmöglichkeiten zur Verfügung.
                        <br>
                      </p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;"><strong>Adresse</strong></h3>
                    </div>
                    <div class="row">
                      <p style="color: #8c143f;">
                        Spezialtischlerei DERHOBEL.at e.U.<br>
                        Herr Engelbert Tscheschner<br>
                        Sackstraße 22<br>
                        8010 Graz
                      </p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;"><strong>Telefon</strong></h3>
                    </div>
                    <div class="row">
                      <p style="color: #8c143f;">
                        0316 84 85 16<br>
                        0676 84 75 12 100
                      </p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;"><strong>E-Mail</strong></h3>
                    </div>
                    <div class="row">
                      <p style="color: #8c143f;">
                        office@derhobel.at
                      </p>
                    </div>
                    <div class="row">
                      <h3 style="color: #8c143f;"><strong>Öffnungszeiten</strong></h3>
                    </div>
                    <div class="row">
                      <p style="color: #8c143f;">
                        Mo. - Do. 7.30 - 16.30 Uhr durchgehend<br>
                        Fr. 7.30 - 13.30 Uhr durchgehend<br>
                        Sa. - So. geschlossen
                      </p>
                    </div>
                  </div>
                  <div class="col" class="center"> <img style="text-align: center;"
                      src="/assets/img/plan_der_hobel.jpg"></div>
                </div>
              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
    </mat-card>
  </div>
</div>
