<div style=" overflow-y: hidden;">

  <div style="padding: 2px; margin-top: 8px; overflow-y: scroll;" class="container">

    <h1 style="text-align: center;">Unsere Leistungen</h1>

    <mat-card class="example-card" appMaterialElevation [defaultElevation]="0" [raisedElevation]="24"
      style="margin: 4px;">

      <mat-selection-list #shoes [multiple]="false">
        <mat-list-option *ngFor="let service of typesOfServices" [value]="service">
          {{service}}
        </mat-list-option>
      </mat-selection-list>

      <!-- <p>
        Option selected: {{shoes.selectedOptions.selected[0]?.value}}
      </p> -->

    </mat-card>
  </div>
</div>
