/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild } from '@angular/core';
// import {} from 'googlemaps';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit {
  constructor() {}
  @ViewChild('gmap', { static: true }) gmapElement: any;
  // map: google.maps.Map;

  ngOnInit(): void {
    //   var mapProp = {
    //     center: new google.maps.LatLng(18.5793, 73.8143),
    //     zoom: 15,
    //     mapTypeId: google.maps.MapTypeId.ROADMAP,
    //   };
    //   this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    // }
  }
}
