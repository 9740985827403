import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialsModule } from './shared/materials.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { NewModelsComponent } from './components/kfz-mueller/new-models/new-models.component';
import { MapComponent } from './components/kfz-mueller/map/map.component';
import { TeamComponent } from './components/kfz-mueller/team/team.component';
import { OurServicesComponent } from './components/kfz-mueller/our-services/our-services.component';
import { ContactComponent } from './components/kfz-mueller/contact/contact.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MaterialElevationDirective,
    NewModelsComponent,
    MapComponent,
    TeamComponent,
    OurServicesComponent,
    ContactComponent,
  ],
  imports: [
    AlertModule.forRoot(),
    GoogleMapsModule,
    FlexLayoutModule,
    MaterialsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatTabsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
