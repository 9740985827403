import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit {
  @Input() path: string | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onOpenSidenav(): void {
    // this.sidenavService.toggle();
  }

  onBack(): void {
    this.router.navigate([this.path]);
  }

  onClickHome(): void {
    this.router.navigate(['']);
  }

  onClickNewModels(): void {
    this.router.navigate(['new-models']);
  }

  onClickServices(): void {
    this.router.navigate(['services']);
  }

  onClickTeam(): void {
    this.router.navigate(['team']);
  }

  onClickMap(): void {
    this.router.navigate(['map']);
  }

  onClickContact(): void {
    this.router.navigate(['contact']);
  }
}
