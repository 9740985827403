import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css'],
})
export class OurServicesComponent implements OnInit {
  typesOfServices: string[] = [
    'Verkauf',
    'Reparaturen',
    'Pickerl',
    'Service',
    'Lackierung',
    'Mobiler Ladeservice',
  ];

  constructor() {}

  ngOnInit(): void {}
}
