<!-- <div class="divWrapper">
  <div class="divHeader">
    <app-header></app-header>
  </div>
  <div class="divContent">
    <router-outlet></router-outlet>
  </div>
  <div class="divFooter">
    <app-footer></app-footer>
  </div>
</div> -->
<!-- <div class="container">
  <div class="box box-1">box 1</div>
  <div class="box box-2">box 2</div>
  <div class="box box-3">box 3</div>
</div> -->

<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
