<div style="padding: 2px; margin-top: 8px; overflow-y: scroll;" class="container">
  <mat-card color="primary" style="margin: 4px;">
    <div class="row">
      <div class="col">
        <p style="text-align: center; margin: 0%;">
          <img style="text-align: center;" src="/assets/img/logo_footer.png">
        </p>
      </div>
      <div class="col">
        <h4>Der Hobel</h4>
        <span>Sackstraße 22</span><br>
        <span>8010 Graz</span>
        <p>
          <a href="tel:+43316848516">+43 316 84 85 16</a><br>
          <a href="mailto:office@derhobel.at">office@derhobel.at</a>
        </p>
      </div>
      <div class="col">
        <h4>Öffnungszeiten:</h4>
        <div>
          <div>Mo 07:30 bis 16:30</div>
          <div>Di 07:30 bis 16:30</div>
          <div>Mi 07:30 bis 16:30</div>
          <div>Do 07:30 bis 16:30</div>
          <div>Fr 07:30 bis 13:30</div>
          <div>durchgehend</div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
